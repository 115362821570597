// # 鉴权登录注册服务

import http from "@/utils/axios";

// 短信登录
export const loginByPassword = (params) => {
  return http.post("/user/login/smsLogin", params);
};

// 发送验证码
export const loginByVerifyCode = (params) => {
  return http.post("/user/login/smsLoginSmsCode", params);
};

// 添加姓名
export const loginByUserName = (params) => {
  return http.post("/user/login/addInfoToLogin", params, {
    requestOptions: { routeName: "habit" },
  });
};

// 获取用户基本信息
export const getUserBaseInfo = (data) => {
  return http.post("/user/userInfo/baseInfo", data, {
    requestOptions: {
      isMsg: false,
    },
  });
};
// 获取分享人用户基本信息
export const shareUserBaseInfo = (data) => {
  return http.post("user/userInfo/shareUserBaseInfo", data, {
    requestOptions: {
      isMsg: true,
    },
  });
};
