import axios from "axios";
import { Toast } from "vant";
// import { toLogin } from "@/common";
import { localProxyStorage } from "@/utils/storage";
import { apiURL } from "@/config";
// import { clearStorage } from "@/utils";
import router from "@/router";

const contentType = {
  form: "application/x-www-form-urlencoded",
  json: "application/json",
  file: "multipart/form-data",
};

const http = axios.create({
  baseURL: apiURL,
  timeout: 12000,
  headers: {
    "Content-Type": contentType.json,
    platform: "web_h5",
  },
  requestOptions: {
    isMsg: true, // 判断响应拦截data.code不是200是否Toast提示
    routeName: true, // 匹配路由名添加header参数
    isTransformResponseError: true, // 是否需要对返回异常进行处理
    orgId: null,
    setTypeFile: false,
  },
});

// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    console.log(config?.requestOptions.routeName);
    if (localProxyStorage?.user?.token) {
      config.headers["token"] = localProxyStorage.user.token;
    }
    if (config?.requestOptions?.routeName) {
      if (localProxyStorage?.user?.isOrg) {
        config.headers["orgId"] =
          localProxyStorage.user?.isUseOrgId ||
          localProxyStorage.user?.orgInfoList[0]?.orgId ||
          "0";
      } else {
        // 散客 orgId 需传 0
        config.headers["orgId"] = "0";
      }
      if (localProxyStorage?.user?.orgUserId) {
        config.headers["orgUserId"] = localProxyStorage.user?.orgUserId;
      } else {
        config.headers["orgUserId"] = "0";
      }

      if (config?.requestOptions?.orgId) {
        config.headers["orgId"] = config?.requestOptions?.orgId;
      }
    }

    if (config?.requestOptions?.setTypeFile) {
      config.headers["Content-Type"] = contentType.file;
    }
    // 在发送请求之前做些什么
    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // console.log(response);
    // 对响应数据做点什么
    const { status, data, config } = response;
    const isMsg = config?.requestOptions?.isMsg;
    const isTransformResponseError = config?.requestOptions?.isTransformResponseError;
    if (status === 200) {
      if (data.code === 200) {
        return data;
      } else if (data.code === 803) {
        // 业务异常
        if (isMsg) {
          Toast({
            message: data.message || "接口异常",
            icon: require("@/assets/images/login/login_warning.png"),
          });
        }
        return Promise.reject(data);
      } else if (data.code === 1008) {
        // 用户登录失效，清空
        if (isTransformResponseError) {
          localProxyStorage.user = {};
        }
        if (isMsg) {
          Toast({
            message: data.message || "接口异常",
            icon: require("@/assets/images/login/login_warning.png"),
            duration: 2000,
          });
        }
        if (!localProxyStorage?.app?.isAppOpen) {
          const backUrl = location.pathname + location?.search;
          router.push({
            path: "/smsCode",
            query: {
              backUrl: encodeURIComponent(backUrl),
            },
          });
        }
        return Promise.reject(data);
      } else if (data.code === 1007) {
        // token为空
        if (isMsg) {
          Toast({
            message: data.message || "接口异常",
            icon: require("@/assets/images/login/login_warning.png"),
            duration: 2000,
          });
        }
        if (!localProxyStorage?.app?.isAppOpen) {
          const backUrl = location.pathname + location?.search;
          router.push({
            path: "/smsCode",
            query: {
              backUrl: encodeURIComponent(backUrl),
            },
          });
        }
        return Promise.reject(data);
      } else {
        if (isMsg) {
          Toast({
            message: data.message || "接口异常",
            icon: require("@/assets/images/login/login_warning.png"),
          });
        }
        return Promise.reject(data);
      }
    } else {
      console.log("网络请求异常：", status);
    }

    return response;
  },
  function (error) {
    // 对响应错误做点什么
    // Toast("服务器异常，请稍后再试");
    Toast({
      message: "服务器异常，请稍后再试",
      icon: require("@/assets/images/login/login_warning.png"),
    });
    return Promise.reject(error);
  }
);

export default http;
