export default [
  {
    path: "/sj-app/agreement",
    name: "Agreement",
    component: () => import("../views/account/agreement.vue"),
    meta: { title: "上进青年用户协议" },
  },
  {
    path: "/sj-app/privacy",
    name: "Privacy",
    component: () => import("../views/account/privacy.vue"),
    meta: { title: "上进青年隐私保护协议" },
  },
  {
    path: "/sj-app/download",
    name: "Download",
    component: () => import("../views/app/download/index.vue"),
    meta: { title: "下载上进青年App" },
  },
];
